<!-- =========================================================================================
File Name: RegisterJWT.vue
Description: Register Page for JWT
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/register.jpg" alt="register" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="px-12 pt-12 pb-12 register-tabs-container" v-if="registrationDone">
                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Successful Registration</h4>
                  <tp-cardtext v-bind:min-height="150">
                    <p>Thank you for your interest in Travelify. One of our sales team will be in touch shortly with more information.</p>
                    <vs-button type="border" to="/login" class="mt-6">Go to login page</vs-button>
                  </tp-cardtext>
                </div>
              </div>
              <div class="px-12 pt-12 pb-12 register-tabs-container" v-if="!registrationDone">
                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Travelify :: Register Your Interest</h4>
                  <p>Fill the form below to register your company interest in Travelify and we will get back to you shortly to discuss your requirements.</p>
                </div>
                <div class="clearfix">
                  <vs-input
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    label-placeholder="First Name"
                    name="firstName"
                    placeholder="First Name"
                    v-model="firstName"
                    class="w-full mt-6"/>
                  <span class="text-danger text-sm">{{ errors.first('firstName') ? 'First Name is required' : '' }}</span>

                  <vs-input
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    label-placeholder="Last Name"
                    name="lastName"
                    placeholder="Last Name"
                    v-model="lastName"
                    class="w-full mt-6"/>
                  <span class="text-danger text-sm">{{ errors.first('lastName') ? 'Last Name is required' : '' }}</span>

                  <vs-input
                    v-validate="'required|email'"
                    data-vv-validate-on="blur"
                    name="email"
                    type="email"
                    label-placeholder="Email"
                    placeholder="Email"
                    v-model="email"
                    class="w-full mt-6"/>
                  <span class="text-danger text-sm">{{ errors.first('email') ? 'Email is required' : '' }}</span>

                  <!-- <vs-input
                    v-validate="'required|alpha_dash'"
                    data-vv-validate-on="blur"
                    label-placeholder="Telephone Number"
                    name="phoneNumber"
                    placeholder="Telephone Number"
                    v-model="phoneNumber"
                    class="w-full mt-6"/>
                  <span class="text-danger text-sm">{{ errors.first('phoneNumber') ? 'Telephone Number is required' : '' }}</span> -->

                  <div class="w-full mt-6">
                      <VuePhoneNumberInput
                        v-model="phoneNumber" 
                        default-country-code="GB"
                        @update="phoneNumberResult=JSON.parse(JSON.stringify($event))"
                        :preferred-countries="['GB', 'US' ,'FR', 'BE', 'DE']"
                        no-flags
                        show-code-on-list
                      />
                  </div>

                  <vs-input
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    label-placeholder="Company Name"
                    name="companyName"
                    placeholder="Company Name"
                    v-model="companyName"
                    class="w-full mt-6"/>
                  <span class="text-danger text-sm">{{ errors.first('companyName') ? 'Company Name is required' : '' }}</span>

                  <vs-input
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    label-placeholder="Role in Company"
                    name="companyRole"
                    placeholder="Role in Company"
                    v-model="companyRole"
                    class="w-full mt-6"/>
                  <span class="text-danger text-sm">{{ errors.first('companyRole') ? 'Company Role is required' : '' }}</span>

                  <!-- <vs-select class="w-full mt-6" v-model="countryCode">
                    <vs-select-item :key="index" :value="item.code" :text="item.label" v-for="(item,index) in countryOptions" />
                  </vs-select> -->
                  
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-select placeholder="Company Type" name="companyType" class="w-full mt-6" v-model="companyType" :options="companyTypeOptions"></v-select>
                    <span class="text-danger text-sm">{{ errors[0] ? 'Company Type is required' : ''  }}</span>
                  </ValidationProvider>


                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-select placeholder="Country" name="countryCode" class="w-full mt-6" v-model="countryCode" :options="countryOptions"></v-select>
                    <span class="text-danger text-sm">{{ errors[0] ? 'Country is required' : ''  }}</span>
                  </ValidationProvider>

                  <vs-textarea 
                    label-placeholder="Message"
                    name="message"
                    placeholder="Message"
                    class="w-full mt-6"
                    counter="256"
                    maxlength="256"
                    v-model="message" 
                    rows="3" 
                  />

                  <div class="w-full mt-6">
                    <vue-recaptcha 
                      :sitekey="recaptchaSiteKey" 
                      ref="recaptcha" 
                      @verify="onVerify"
                    ></vue-recaptcha>
                  </div>

                  <vs-checkbox
                    class="mt-6">I accept the terms & conditions.</vs-checkbox>
                  <vs-button type="border" to="/login" class="mt-6">Login</vs-button>
                  <vs-button
                    class="float-right mt-6"
                    @click="registerCompany"
                    :disabled="!validateForm">Register Interest
                  </vs-button>
                </div>
                <vs-divider>EXISTING COMPANIES</vs-divider>
                <div>
                  If your company already has an account with Travelify and you need a login, please speak to your company administrator to add you as a new user. This form is for new companies to register their interest in the product.
                </div>
              </div>

            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import VueRecaptcha from 'vue-recaptcha'
import TpCardtext from '@/components/travio-pro/TpCardtext'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  components :{
    vSelect,
    ValidationProvider,
    VueRecaptcha,
    TpCardtext,
    VuePhoneNumberInput
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      companyName: "",
      companyRole: "",
      countryCode: "",
      companyType: "",
      message: "",
      isRobot: true,
      registrationDone: false,
      recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      phoneNumberResult: null
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any()
    },
    countryOptions () {
      return this.$store.state.lookups.countryOptions
    },
    companyTypeOptions () {
      return this.$store.state.lookups.companyTypeOptions
    },
  },
  methods: {
    checkLogin() {
      return true;
    },
    registerCompany() {
      // If form is not validated or user is already login return
      if (!this.validateForm || (this.phoneNumber && !this.phoneNumberResult.isValid)) {
        this.$vs.notify({
          title: 'Register Interest Failed',
          text: 'Please check form values',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
        return;
      }

      if (this.isRobot) {
        this.$vs.notify({
          title: 'Register Interest Failed',
          text: 'Please verify you are not a robot',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
      }

      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phoneNumber: this.phoneNumberResult && this.phoneNumberResult.formattedNumber,
        companyName: this.companyName,
        companyRole: this.companyRole,
        countryCode: this.countryCode.code,
        companyType: this.companyType,
      };
      this.$vs.loading();
      this.$store.dispatch("auth/registerCompany", payload)
        .then(response => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: 'Success',
            text: 'Company and user creaeted',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
          this.registrationDone = true;
        }).catch(error => {
          this.$vs.loading.close();
          this.$vs.notify({
            time: 4000,
            title: 'Failed',
            text: `Unable to create company and user ${error.response.data.title}`,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });
      });
    },
    onVerify (response) {
      if (response) {
        this.isRobot = false 
      };
    },
  }
};
</script>